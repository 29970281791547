import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getPageId, getPageSlug } from "../../reducers/PageReducer";
import { updatePagePublishStatus, pageStatus } from "../../api";

function CampaignersPopup({ publish_status, lang }) {
  const NEXT_PUBLIC_CHAMPAIGN_API_HOST =
    process.env.NEXT_PUBLIC_CHAMPAIGN_API_HOST;

  const [isOpen, setOpen] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const pageSlug = useSelector(getPageSlug);
  const pageId = useSelector(getPageId);
  const [copied, setCopied] = useState(false);
  const [updating, setUpdating] = useState(false);

  const config = {
    published: {
      bgColor: "bg-green-100",
      title: "Published",
      desc: "Anyone with the link can view.",
      buttonTitle: "Unpublish",
      action: "unpublished",
    },
    unpublished: {
      bgColor: "bg-orange-a11yorange",
      title: "Not published",
      desc: "Only campaigners can view.",
      buttonTitle: "Publish",
      action: "published",
    },
  };

  const showAlert = (params) => {
    const { action } = params;
    if (action === "unpublished") {
      if (
        window.confirm(
          "Are you sure you want to unpublish this page? It will become inaccessible except to logged-in campaigners"
        )
      )
        updatePageStatus(params);
    } else {
      updatePageStatus(params);
    }
  };

  const handleCopyLinkClick = (event) => {
    event.preventDefault();
    const url = "https://action.eko.org/a/" + pageSlug;
    navigator.clipboard?.writeText(url);
    setCopied(true);
  };

  const handleEditClick = () => {
    window.location = `${NEXT_PUBLIC_CHAMPAIGN_API_HOST}/a/${pageSlug}/edit`;
  };

  const updatePageStatus = (params) => {
    const { action, buttonTitle } = params;
    setUpdating(true);
    try {
      const payload = {
        page: { page: { publish_status: action } },
        commit: buttonTitle,
      };
      updatePagePublishStatus(pageId, payload).then((resp) => {
        if (resp.id) {
          if (action === "unpublished") {
            window.location = `${window.location.origin}/page/${pageSlug}${window.location.search}`;
          } else {
            window.location = `${window.location.origin}/a/${pageSlug}${window.location.search}`;
          }
        } else {
          window.alert(JSON.stringify(resp.errors[0]));
          setUpdating(false);
        }
      });
    } catch (error) {}
  };

  useEffect(() => {
    let isMounted = true;

    const validateSessionAsync = async () => {
      const { published } = (await pageStatus()) || {};
      if (published) {
        setIsLoggedIn(true);
      }
    };
    if (isMounted && !isLoggedIn) {
      validateSessionAsync();
    }

    return () => {
      isMounted = false;
    };
  }, [isLoggedIn]);

  useEffect(() => {
    let isMounted = true;
    if (copied && isMounted) {
      setTimeout(() => {
        setCopied(false);
      }, 3500);
    }
    return () => {
      isMounted = false;
    };
  }, [copied]);

  return (
    <>
      {isOpen && isLoggedIn && (
        <>
          <div
            id="campaigners-popup"
            className={`fixed bottom-0 left-0 z-50 mx-auto flex w-auto max-w-md flex-col items-start space-y-4 rounded-lg border border-gray-200 ${config[publish_status].bgColor} p-4 shadow-lg dark:border-gray-700 dark:bg-green-700 rounded-sm`}
            role="dialog"
            aria-labelledby="pageStatusTitle"
            aria-describedby="pageStatusDescription"
          >
            <div className="flex w-full items-start justify-between">
              <h2
                id="pageStatusTitle"
                className="text-lg font-bold text-gray-900 dark:text-white"
                data-testid="campaigners-popup-title"
              >
                {config[publish_status].title}
              </h2>
              <button
                aria-label="Close campaigners popup"
                className="ml-auto bg-transparent text-gray-900 transition duration-200 ease-in-out hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50 dark:text-white dark:hover:text-gray-300"
                onClick={() => setOpen(false)}
              >
                <span className="sr-only">Close</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              </button>
            </div>
            <p
              id="pageStatusDescription"
              className="text-sm text-gray-900 dark:text-gray-400"
            >
              {config[publish_status].desc}
              <span className="text-xs text-gray-600 dark:text-gray-300 block">
                You are seeing this page in Pronto 🎉
              </span>
            </p>
            <div className="ml-auto flex items-center gap-2">
              <button
                className="ring-offset-background focus-visible:ring-ring bg-black text-white hover:bg-gray-800 inline-flex h-10 items-center justify-center whitespace-nowrap rounded-md px-4 py-2 text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 flex items-center"
                aria-label={config[publish_status].buttonTitle}
                data-testid="campaigners-popup-button"
                onClick={() => {
                  showAlert(config[publish_status]);
                }}
              >
                {updating ? (
                  <>
                    <span className="pr-2">Wait</span>
                    <svg
                      className="animate-spin m-auto h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  </>
                ) : (
                  config[publish_status].buttonTitle
                )}
              </button>
              <button
                className="ring-offset-background focus-visible:ring-ring bg-black text-white hover:bg-gray-800 inline-flex h-10 animate-subtleBounce items-center justify-center whitespace-nowrap rounded-md px-4 py-2 text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50"
                aria-label="Copy URL"
                onClick={handleCopyLinkClick}
                data-testid="campaigners-popup-copyLink"
              >
                {copied ? "Copied!" : "Copy URL"}
              </button>
              <button
                className="ring-offset-background focus-visible:ring-ring bg-black text-white hover:bg-gray-800 inline-flex h-10 items-center justify-center whitespace-nowrap rounded-md px-4 py-2 text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50"
                aria-label="Edit page"
                onClick={handleEditClick}
              >
                Edit
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default CampaignersPopup;
