import { forwardRef } from "react";
import { useSelector } from "react-redux";
import Donation from "..";
import { getCtaCompleted } from "../../../reducers/ActionFormReducer";
import ShareAskPanel from "./ShareAskPanel";
import { useFeatureValue } from "@growthbook/growthbook-react";

const ScrollToDonate = forwardRef((props, ref) => {
  const signed = useSelector(getCtaCompleted);
  const { belowDonateAsk: showSharePanel } = useFeatureValue("share-panel") || {
    belowDonateAsk: false,
  };
  const { buttons } = props;
  return (
    <>
      <div
        ref={ref}
        id="donation-section"
        className="hidden min-h-screen flex flex-col justify-center items-center mt-5 lg:mt-30 lg:mb-60 mb-40"
      >
        <div className="md:w-1/2 w-full h-full mb-2 lg:mb-1 md:mb-1">
          {signed && <Donation scrollToDonate={true} />}
        </div>
        {showSharePanel && <ShareAskPanel buttons={buttons} />}
      </div>
    </>
  );
});

ScrollToDonate.displayName = "ScrollToDonate";

export default ScrollToDonate;
